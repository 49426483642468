import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/abacus/[tableId]": [3],
		"/announcements": [4],
		"/articles": [5],
		"/articles/full_text": [7],
		"/articles/[articleId]": [6],
		"/auth/login": [8],
		"/auth/logout": [9],
		"/categories": [10],
		"/categories/backfill": [12],
		"/categories/backfill/confirm": [13],
		"/categories/backfill/status": [14],
		"/categories/new": [15],
		"/categories/rules": [16],
		"/categories/rules/[ruleId]": [17],
		"/categories/topics": [18],
		"/categories/topics/[topicId]": [19],
		"/categories/[categoryId]": [11],
		"/dashboard": [20],
		"/dashboard/client": [21],
		"/dashboard/headlines": [22],
		"/dashboard/push": [23],
		"/experiments": [24],
		"/experiments/legacy/[experimentName]": [26],
		"/experiments/[experimentName]": [25],
		"/feedback": [27],
		"/feedback/[feedbackId]": [28],
		"/gates": [29],
		"/gates/[gateName]": [30],
		"/headlines": [31],
		"/headlines/[headlineId]": [32],
		"/notifications/manual": [33],
		"/notifications/sampler": [34],
		"/publishers": [35],
		"/publishers/new": [37],
		"/publishers/[publisherId]": [36],
		"/ratings/[ratingId]": [38],
		"/regions": [39],
		"/regions/new": [41],
		"/regions/[regionId]": [40],
		"/reports": [42],
		"/reports/[reportId]": [43],
		"/site": [44],
		"/site/homepage_modules": [45],
		"/site/homepage_modules/[id]": [46],
		"/site/shopping_modules": [47],
		"/site/shopping_modules/[id]": [48],
		"/test_prompt": [49],
		"/test_users": [50],
		"/tools/id/[id]": [51],
		"/tools/knobs": [52],
		"/tools/schema": [53],
		"/users": [54],
		"/users/[userId]": [55],
		"/writers": [56],
		"/writers/[writerId]": [57]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';