import type { HandleClientError } from '@sveltejs/kit';

import { getWireErrorFromResponse } from '$lib/api/wire';

export const handleError: HandleClientError = async ({ error, message }) => {
  if (error instanceof Response) {
    try {
      const backendError = await getWireErrorFromResponse(error);

      return {
        message: backendError.message
      };
    } catch (parseError) {
      console.error('Encountered an error while parsing the error response from the backend:');
      console.error(parseError);

      // pass through
    }
  }

  return {
    message
  };
};
